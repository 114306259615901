<template>
  <svg width="20" height="24" viewBox="0 0 20 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask
      id="mask0_147_14948"
      style="mask-type: alpha"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="20"
      height="24"
    >
      <path
        d="M18.3824 24H1.61765C0.724246 24 0 23.2325 0 22.2857V1.71429C0 0.767512 0.724246 0 1.61765 0H12.5L20 7.94805V22.2857C20 23.2325 19.2757 24 18.3824 24Z"
        fill="url(#paint0_linear_147_14948)"
      />
    </mask>
    <g mask="url(#mask0_147_14948)">
      <path
        d="M18.3824 24H1.61765C0.724246 24 0 23.2325 0 22.2857V1.71429C0 0.767512 0.724246 0 1.61765 0H12.5L20 7.94805V22.2857C20 23.2325 19.2757 24 18.3824 24Z"
        fill="url(#paint1_linear_147_14948)"
      />
      <g filter="url(#filter0_d_147_14948)">
        <path
          d="M20 7.94805H14.1176C13.2243 7.94805 12.5 7.18054 12.5 6.23377V0L20 7.94805Z"
          fill="url(#paint2_linear_147_14948)"
        />
      </g>
      <path
        d="M5.16016 14.4971V16H4.28125V11.7344H5.94531C6.26562 11.7344 6.54688 11.793 6.78906 11.9102C7.0332 12.0273 7.2207 12.1943 7.35156 12.4111C7.48242 12.626 7.54785 12.8711 7.54785 13.1465C7.54785 13.5645 7.4043 13.8945 7.11719 14.1367C6.83203 14.377 6.43652 14.4971 5.93066 14.4971H5.16016ZM5.16016 13.7852H5.94531C6.17773 13.7852 6.35449 13.7305 6.47559 13.6211C6.59863 13.5117 6.66016 13.3555 6.66016 13.1523C6.66016 12.9434 6.59863 12.7744 6.47559 12.6455C6.35254 12.5166 6.18262 12.4502 5.96582 12.4463H5.16016V13.7852ZM11.623 16H10.7441L9.0332 13.1934V16H8.1543V11.7344H9.0332L10.7471 14.5469V11.7344H11.623V16ZM15.7656 15.4609C15.6074 15.6504 15.3838 15.7979 15.0947 15.9033C14.8057 16.0068 14.4854 16.0586 14.1338 16.0586C13.7646 16.0586 13.4404 15.9785 13.1611 15.8184C12.8838 15.6562 12.6689 15.4219 12.5166 15.1152C12.3662 14.8086 12.2891 14.4482 12.2852 14.0342V13.7441C12.2852 13.3184 12.3564 12.9502 12.499 12.6396C12.6436 12.3271 12.8506 12.0889 13.1201 11.9248C13.3916 11.7588 13.709 11.6758 14.0723 11.6758C14.5781 11.6758 14.9736 11.7969 15.2588 12.0391C15.5439 12.2793 15.7129 12.6299 15.7656 13.0908H14.9102C14.8711 12.8467 14.7842 12.668 14.6494 12.5547C14.5166 12.4414 14.333 12.3848 14.0986 12.3848C13.7998 12.3848 13.5723 12.4971 13.416 12.7217C13.2598 12.9463 13.1807 13.2803 13.1787 13.7236V13.9961C13.1787 14.4434 13.2637 14.7812 13.4336 15.0098C13.6035 15.2383 13.8525 15.3525 14.1807 15.3525C14.5107 15.3525 14.7461 15.2822 14.8867 15.1416V14.4062H14.0869V13.7588H15.7656V15.4609Z"
        fill="white"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_147_14948"
        x="-10.5"
        y="-24"
        width="57.5"
        height="57.948"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="2" dy="1" />
        <feGaussianBlur stdDeviation="12.5" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.33 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_147_14948" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_147_14948"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_147_14948"
        x1="10"
        y1="0"
        x2="10"
        y2="24"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FF7979" />
        <stop offset="1" stop-color="#E85555" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_147_14948"
        x1="10"
        y1="0"
        x2="10"
        y2="24"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FFA216" />
        <stop offset="1" stop-color="#EF8100" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_147_14948"
        x1="16.25"
        y1="0"
        x2="16.25"
        y2="7.94805"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FF7A1B" />
        <stop offset="1" stop-color="#E86100" />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "AI",
  mixins: [],
  props: {},
  components: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {},
  computed: {},
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
</style>
